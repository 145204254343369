/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

(window ).__rewriteFramesAssetPrefixPath__ = '';

import { GLOBAL_OBJ } from '@sentry/utils';

/* eslint-disable no-constant-condition */

const globalObj = GLOBAL_OBJ ;

globalObj.SENTRY_RELEASE = { id: 'd03430630be5e5cd1b581b32b6a286b3002b2279' };

// Enable module federation support (see https://github.com/getsentry/sentry-webpack-plugin/pull/307)
if ('') {
  const key = 'undefined' ? '@undefined' : '';
  globalObj.SENTRY_RELEASES = globalObj.SENTRY_RELEASES || {};
  globalObj.SENTRY_RELEASES[key] = { id: 'd03430630be5e5cd1b581b32b6a286b3002b2279' };
}

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://6da2fa0438a443e3822a48b9da9b4a73@o102000.ingest.sentry.io/4504158023974912',
  tracesSampleRate: 1.0,
});
